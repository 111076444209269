import { FacadeServiceClient } from './api/FacadeServiceClient';
import { ApplicationConfig } from '../config/ApplicationConfig';
import { AnalyticsClient } from './analytics/AnalyticsClient';
import { Stage } from '../model/Stage';
import { GoogleAnalyticsClient } from './analytics/GoogleAnalyticsClient';
import { EventFactory } from './analytics/EventFactory';
import { DummyAnalyticsClient } from './analytics/DummyAnalyticsClient';
import {GaramFacadeServiceClient} from "./api/GaramFacadeServiceClient";
import {ChatClient} from "./chat/ChatClient";
import {StreamChatClient} from "./chat/StreamChatClient";
import {StreamChat} from "stream-chat";
import {CookieStorage} from "./cookie/CookieStorage";
import {PlaygroundChatClient} from "./chat/PlaygroundChatClient";
import {PlaygroundFacadeServiceClient} from "./api/PlaygroundFacadeServiceClient";
import { URLParamAppParameterReader } from './param/URLParamAppParameterReader';
import { ActionMessageClient } from './message/ActionMessageClient';

export class ClientFactory {
    private static chatClient: ChatClient | null = null;

    private constructor() {}

    public static createFacadeServiceClient(): FacadeServiceClient {
        const garamFacadeServiceClient = new GaramFacadeServiceClient(ApplicationConfig.hostURL);

        if (ClientFactory.isPlaygroundMode()) {
            return new PlaygroundFacadeServiceClient(garamFacadeServiceClient);
        }

        return garamFacadeServiceClient;
    }

    public static createURLParameterReader(): URLParamAppParameterReader {
        return new URLParamAppParameterReader();
    }

    public static createAnalyticsClient(serviceId: string): AnalyticsClient {
        if (ApplicationConfig.stage === Stage.Prod || ApplicationConfig.stage === Stage.Beta) {
            return new GoogleAnalyticsClient(serviceId, new EventFactory());
        }

        return new DummyAnalyticsClient();
    }

    public static createChatClient(): ChatClient {
        if (ClientFactory.chatClient === null) {
            if (ClientFactory.isPlaygroundMode()) {
                ClientFactory.chatClient = new PlaygroundChatClient(ApplicationConfig.hostURL);
            } else {
                ClientFactory.chatClient =  new StreamChatClient(StreamChat.getInstance(ApplicationConfig.streamChatAPIKey))
            }
        }

        return ClientFactory.chatClient;

    }

    public static createCookieStorage(): CookieStorage {
        return new CookieStorage();
    }

    public static isPlaygroundMode(): boolean {
        return new URL(window.location.href).searchParams.get('mode') === 'playground';
    }

    public static createActionMessageClient(): ActionMessageClient {
        return new ActionMessageClient();
    }
}
